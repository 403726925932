import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ISingleButtonProps } from 'types';
import { ReactComponent as Logo } from 'images/pxlCheck.svg';
import SwitchLanguage from 'components/lang/SwitchLanguage';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE, LOCAL_STORAGE_LNG } from 'constants/Static';
import * as commonActions from 'store/features/common/actions';
import { clearLocalStoragePartly } from 'util/storageHelper';
import { ERoutePaths } from 'enums';

const LogOut = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const loginClickHandler = () => {
    const urlParams = new URLSearchParams(location.search);
    const tid = urlParams.get('tid');
    if (tid) {
      dispatch(commonActions.setDefaultTransactionCode(tid));
    }
    clearLocalStoragePartly([LOCAL_STORAGE_LNG]);
    window.location.replace(ERoutePaths.signIn);
  };

  const buttonPropsData: ISingleButtonProps = {
    isActive: true,
    onClick: loginClickHandler,
    textButton: 'sign-in.button',
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
  };

  return (
    <div className="main-content">
      <div className="container">
        <div className="logout-container">
          <div className="identity-container">
            <Logo />
            <span className="identity-text">{t('sign-in.header')}</span>
          </div>
          <div className='log-out-description-container'>
            <p> {t('log-out.description-text')}</p>
            <p> {t('log-out.description-option-text')}</p>
          </div>
          <div className="button-container">
            <SingleButton {...buttonPropsData} />
          </div>
        </div>
      </div>
      <SwitchLanguage />
    </div>
  );
};

export default LogOut;
