
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { ReactComponent as Info } from 'images/info.svg';
import MultipleSelect from 'components/select/MultipleSelect';
import ToolTip from 'components/toolTipField/ToolTipField';
import VideoPlayer from 'components/videoPlayer/VideoPlayer';
import { EAnswers, EStatuses, EFileTypes } from 'enums';
import { IRootState } from 'store/types';
import { IOptionType } from 'types';

const RejectionReasons = [
  {
    'label': 'identity.ld.rejection-reason-1.title',
    'value': 'reason-1'
  },
  {
    'label': 'identity.ld.rejection-reason-2.title',
    'value': 'reason-2'
  },
  {
    'label': 'identity.ld.rejection-reason-3.title',
    'value': 'reason-3'
  },
  {
    'label': 'identity.ld.rejection-reason-4.title',
    'value': 'reason-4'
  },
  {
    'label': 'identity.ld.rejection-reason-5.title',
    'value': 'reason-5'
  },
  {
    'label': 'identity.ld.rejection-reason-6.title',
    'value': 'reason-6'
  }
]

const statusMessages = {
  [EStatuses.pass]: 'identity.ld.result.label.pass',
  [EStatuses.fail]: 'identity.ld.result.label.fail',
}

const Liveness = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionCode, transactionData } = state.reviewResult;
  const { faceLiveness } = transactionData;
  const { livenessCheck } = faceLiveness || {};
  const [guidelinesAccepted, setGuidelinesAccepted] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const result = EStatuses[livenessCheck?.result as keyof typeof EStatuses] || EStatuses.pass;
  const [reasons, setReasons] = useState<IOptionType[]>([]);

  const rejectionReasonsProps = {
    value: reasons,
    handler: (selected: IOptionType[]) => setReasons(selected),
    placeholder: t('identity.ld.rejection-reason-placeholder'),
    options: RejectionReasons.map((reason: IOptionType) => ({
      label: t(reason.label),
      value: reason.value,
    })),
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === EAnswers.yes) {
      setReasons([]);
      setSelectedOption(EAnswers.yes);
    } else {
      setSelectedOption(EAnswers.no);
    }
  }

  return (
    <div className="selfie-video-container">
      <p className="video-title">{t('identity.ld.heading')}</p>
      <div className='selfie-video-main'>
        <div className="video-section">
          <VideoPlayer
            transactionCode={transactionCode}
            filetype={EFileTypes.selfieVideo}
            path={location.pathname}
          />
        </div>
        <div className="review-section">
          <p className="video-status">
            {t('identity.ld.result.title')}
            <span className={result}>{t(statusMessages[result])}</span></p>
          <div className="line"></div>
          <div className="review">
            <span className='review-text'>{t('identity.ld.guideline.heading')}</span>
            <div className='checkbox-section'>
              <input
                type="checkbox"
                checked={guidelinesAccepted}
                onChange={(event) => setGuidelinesAccepted(event.target.checked)}
              />
              <p className='review-description'>
                {t('identity.ld.guideline.message1')}
                <ToolTip
                  placement='top'
                  tooltipValue={<Trans i18next='identity.ld.guideline.message1.tooltip'/>}
                >
                  <Info />
                </ToolTip>
              </p>
            </div>
            <div className="options">
            </div>
          </div>
          {
            guidelinesAccepted ? <>
              <div className="line"></div>
              <div className="review-buttons">
                <h2 className="description">{t('identity.ld.question.title')}</h2>
                <div className='answer-section'>
                  <Form.Check
                    inline
                    label={t('identity.ld.answer-yes.title')}
                    value={EAnswers.yes}
                    name='identity.ld.answer'
                    className='radio-button'
                    onChange={handleChange}
                    type='radio'
                  />
                  <Form.Check
                    inline
                    label={t('identity.ld.answer-no.title')}
                    value={EAnswers.no}
                    name='identity.ld.answer'
                    className='radio-button'
                    onChange={handleChange}
                    type='radio'
                  />
                </div>
                {
                  selectedOption === 'no' ? <div className='select-reasons'>
                    <MultipleSelect {...rejectionReasonsProps} />
                  </div> : null
                }
              </div>
            </> : null
          }
        </div>
      </div>
    </div>
  );
};

export default Liveness;
