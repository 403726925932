import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SignIn from 'pages/signin/SignIn';
import Dashboard from 'pages/dashboard/Dashboard';
import DatasetContainer from 'pages/dataset/DatasetContainer';
import PublicRoute from 'routing/PublicRoute';
import PrivateRoute from 'routing/PrivateRoute';
import { isAuthorized } from 'util/authService';
import { getStorageData } from 'util/storageHelper';
import * as commonActions from 'store/features/common/actions';
import { IRootState } from 'store/types';
import LogOut from 'pages/logout/LogOut';
import { UnlockTransactionAPI } from 'helper/api/route';
import { ERoutePaths } from 'enums';
import ForgotPassword from 'pages/forgotPassword/ForgotPassword';
import NewPassword from 'pages/newPassword/NewPassword';
import ConditionalRedirect from 'routing/ConditionalRedirect';
import TABoarding from 'pages/taBoard/TABoarding';
import Review from 'pages/review/Review';

const Routing = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state: IRootState = useSelector((rootState: IRootState) => rootState);
  const appConfig = state.common?.appConfig;
  const location = useLocation();
  const redirectUrl = isAuthorized() ? ERoutePaths.dashboard : ERoutePaths.signIn;

  const resetTransactionCode = () => {
    const storageState: string = getStorageData('state') || '';
    const stateData = storageState ? JSON.parse(storageState) : {};
    const { transactionCode = '' } = stateData;

    if (transactionCode) UnlockTransactionAPI(transactionCode);
  };

  const settingLanguage = () => {
    const urlParams = new URLSearchParams(location.search);
    const lang = urlParams.get('lang');
    if (lang && appConfig?.lang?.langs.includes(lang)) {
      i18n.changeLanguage(lang);
    }
  };

  const getDefaultUrl = () => {
    const interval = setInterval(() => {
      resetTransactionCode();
      if (isAuthorized()) {
        window.location.replace(ERoutePaths.dashboard);
      } else {
        window.location.replace(ERoutePaths.logOut);
      }
      clearInterval(interval);
    }, 0);
  };

  useEffect(() => {
    settingLanguage();
    // setInterval added to avoid NS Error in firefox browser
    window.addEventListener('popstate', getDefaultUrl, false);
    return () => window.removeEventListener('popstate', () => ({}));
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tid = urlParams.get('tid');
    if (tid) {
      dispatch(commonActions.setDefaultTransactionCode(tid));
      urlParams.delete('tid');
      navigate(`${location.pathname}?${urlParams.toString()}`);
    }
  }, [location]);


  return (
    <Routes>
      <Route
        path={ERoutePaths.signIn}
        element={<PublicRoute component={<SignIn />} />}
      />
      <Route
        path={ERoutePaths.forgottenPassword}
        element={<PublicRoute component={<ForgotPassword />}/>}
      />
      <Route
        path={ERoutePaths.logOut}
        element={<PublicRoute component={<LogOut />}/>}
      />
      <Route
        path={ERoutePaths.setPassword}
        element={<PublicRoute component={<NewPassword />}/>}
      />
      <Route
        path={ERoutePaths.dashboard}
        element={<PrivateRoute component={<Dashboard />}/>}
      />
      <Route
        path={ERoutePaths.dataset}
        element={<PrivateRoute component={<DatasetContainer />}/>}
      />
      <Route
        path={'/ta-boarding/:transactionCode'}
        element={<PrivateRoute component={<TABoarding />}/>}
      />
      {[ERoutePaths.identityReview, ERoutePaths.addressReview, ERoutePaths.a4scanReview].map(
        (pathname, index) => <Route
          key={index}
          path={`${pathname}/:transactionCode`}
          element={<PrivateRoute component={<Review />}/>}
        />
      )}
      <Route path="*" element={<ConditionalRedirect redirectUrl={redirectUrl} />} />
    </Routes>
  );
};

export default Routing;
