import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const checkResult = [
  {
    title: 'footer.check.doc.message',
    result: 'NO',
  },
  {
    title: 'footer.check.liveness.message',
    result: 'YES',
  },
  {
    title: 'footer.check.face.message',
    result: 'NO',
  },
  {
    title: 'footer.check.address.message',
    result: 'NO',
  },
  {
    title: 'footer.check.a4.message',
    result: 'NO',
  },
];

const Footer = () => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const [postProcessing, setPostProcessing] = useState(false);

  return (
    <div className="footer-container">
      <div className="manual-check">
        <h6>Manual Check</h6>
        {checkResult.map(({ result, title }, index) => (
          <div className="check-item" key={index}>
            <span className={`check-mark ${result === 'YES' ? 'tick' : 'cross'}`}>
              {result === 'YES' ? '✔' : '✘'}
            </span>
            <span>{t(title)}</span>
            <span className={`result ${result === 'YES' ? ' YES' : 'NO'}`}>
              {result}
            </span>
          </div>
        ))}
        <div className="post-processing">
          <div className="line"></div>
          <div>
            <input
              type="checkbox"
              checked={postProcessing}
              onChange={(event) => setPostProcessing(event.target.checked)}
            />
            <label htmlFor="postProcessing">Flag transaction for external post-processing</label>
          </div>
        </div>
      </div>
      <div className="summary">
        <h6>{t('footer.comment.input-title')}</h6>
        <textarea
          placeholder={t('footer.comment.placeholder')}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div className="action-buttons">
          <button>{t('footer.btn.accept')}</button>
          <button>{t('footer.btn.reject')}</button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
