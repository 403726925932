import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SignInAPI } from 'helper/api/route';
import { ISingleButtonProps } from 'types';
import { ReactComponent as Logo } from 'images/pxlCheck.svg';
import { ReactComponent as EyeClose } from 'images/hide.svg';
import { ReactComponent as EyeOpen } from 'images/view.svg';
import Loader from 'components/loader/Loader';
import { getLocalStorageData, getStorageData } from 'util/storageHelper';
import SwitchLanguage from 'components/lang/SwitchLanguage';
import * as rootActions from 'store/actions';
import * as commonActions from 'store/features/common/actions';
import * as agentActions from 'store/features/agentManagement/actions';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE, KEY_CODE } from 'constants/Static';
import TurnstileWidget from 'components/turnstile/TurnstileWidget';
import { isValidEmail } from 'helper/validation';
import { ERoutePaths } from 'enums';

const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('Password1!');
  const [email, setEmail] = useState('anna_ha@zealous.tech');
  const [eyeClose, setEye] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const validated = Boolean(isValidEmail(email) && password);
  const screenIsApproved = Boolean(validated && turnstileToken);

  useEffect(() => {
    const localStorageData: string | null = getLocalStorageData('datasets');
    const sessionStorageData: string | null = getStorageData('state');
    if (localStorageData || sessionStorageData) {
      dispatch(rootActions.reset());
    }
  }, []);

  const clickHandler = () => {
    if (loader || !screenIsApproved) return;
    setLoader(true);
    SignInAPI(email, password, turnstileToken)
      .then((response) => {
        const urlParams = new URLSearchParams(location.search);
        const tid = urlParams.get('tid');
        if (tid) {
          dispatch(commonActions.setDefaultTransactionCode(tid));
        }
        dispatch(agentActions.setAgent(response.data));
        navigate('/');
      })
      .catch(() => {
        setErrorMessage('messages.invalid-request');
        setLoader(false);
      });
  };

  const buttonPropsData: ISingleButtonProps = {
    isActive: screenIsApproved && !loader,
    isDisabled: !screenIsApproved || loader,
    onClick: clickHandler,
    textButton: 'sign-in.button',
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
  };

  const keyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === KEY_CODE.ENTER) {
      clickHandler();
    }
  };

  return (
    <div className="main-content">
      <div className="container">
        <div className="login-container">
          <div className="identity-container">
            <Logo />
            <span className="identity-text">{t('sign-in.header')}</span>
          </div>
          <div className="form-container">
            <div className="error-container">{t(errorMessage)}</div>
            <div className="input-container">
              <input
                className="user-input"
                type="text"
                placeholder={t('sign-in.input.placeholder.username')}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setTurnstileToken('');
                }}
                onKeyDown={(e) => keyPress(e)}
              />
              <span
                className="placeholder"
              >
                {t('sign-in.input.label.username')}
              </span>
            </div>
            <div className="input-container">
              {
                eyeClose ? <EyeOpen
                  onClick={() => setEye(!eyeClose)}
                /> : <EyeClose
                  onClick={() => setEye(!eyeClose)}
                />
              }
              <input
                className="user-input right-addon"
                type={`${eyeClose ? 'text' : 'password'}`}
                placeholder={t('sign-in.input.placeholder.password')}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setTurnstileToken('');
                }}
                onKeyDown={(e) => keyPress(e)}
              />
              <span
                className="placeholder"
              >
                {t('sign-in.input.label.password')}
              </span>
            </div>
            {validated &&
              <TurnstileWidget
                setToken={setTurnstileToken}
                setErrorMessage={setErrorMessage}
                turnstileToken={turnstileToken}
              />
            }
            <div className="button-container">
              <SingleButton {...buttonPropsData} />
              {loader && <div className="loader-container"><Loader /></div>}
            </div>
          </div>

          <div className="forgotten_password_container">
            <hr className="horizontal-line" />
            <div className="forgotten_password_from">
              <Link to={ERoutePaths.forgottenPassword}>{t('login.forgotten-password.lnk')}</Link>
            </div>
          </div>
        </div>
      </div>
      <SwitchLanguage />
    </div>
  );
};

export default SignIn;
