import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as Search } from 'images/search.svg';
import { ReactComponent as Switch } from 'images/redirect.svg';
import { ReactComponent as RefreshButtonIcon } from 'images/refresh.svg';
import Loader from 'components/loader/Loader';
import { IRootState } from 'store/types';
import { getSelectedDataset } from 'helper';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';

const LockTransaction = (props: {
  setSearchModalIsShowing: Function;
  setSwitchModalIsShowing: Function;
  isWaiting: boolean;
  onRefresh: Function;
  startReview: Function;
}) => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const datasets = state.agent?.datasets || [];
  const {
    setSearchModalIsShowing,
    setSwitchModalIsShowing,
    isWaiting,
    onRefresh,
    startReview,
  } = props;
  const defaultDataset = getSelectedDataset(datasets);

  const loading = state?.common?.loading;

  return (
    <div className="new-transactions-container">
      <div className="title">
        <h2>{t('reg.dashboard.new-transaction.transactions-dashboard')}</h2>
      </div>
      <div className="new-transaction-content">
        <div className="app-brand-container">
          <div className="new-transactions-text">
            <span>{defaultDataset?.name}</span>
          </div>
          <div className="new-transactions-text brand">
            <span>{defaultDataset?.title}</span>
          </div>
        </div>
        <div className="button-container">
          <SingleButton
            isActive={true}
            onClick={() => startReview({ transactionCode: '', orderId: '' })}
            textButton={'reg.dashboard.new-transaction.start-review'}
            sizeButton={BUTTON_STYLE.BIG}
            isDisabled={isWaiting}
            radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
            variant={BUTTON_STYLE.OUTLINE_SECONDARY}
          />
          <div className="ta-new-transaction-loader-container">
            {isWaiting && (<Loader />)}
          </div>
        </div>
        <div className="refresh-button-container">
          <SingleButton
            onClick={onRefresh}
            textButton={'reg.dashboard.refresh-data'}
            sizeButton={BUTTON_STYLE.BIG}
            isDisabled={loading}
            radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
            variant={BUTTON_STYLE.OUTLINE_PRIMARY}
            LeftIcon={RefreshButtonIcon}
          />
        </div>
        <div className="action-button-container">
          <div
            className="new-transactions-search-button"
            role="button"
            tabIndex={0}
            onClick={() => setSearchModalIsShowing(true)}
          >
            <Search className="new-transactions-icon" />
            <div className="action-button-title">{t('reg.dashboard.search-transaction')}</div>
          </div>
          <div
            className="new-transactions-switch-button"
            role="button"
            tabIndex={0}
            onClick={() => setSwitchModalIsShowing(true)}
          >
            <Switch className="new-transactions-icon" />
            <div className="action-button-title">{t('reg.dashboard.switch-application')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockTransaction;
