import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'store/types';
import * as commonActions from 'store/features/common/actions';

const Switch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const dashboard = state?.common?.dashboard;

  const onSwitch = (isActive: boolean) => () => {
    if (dashboard) {
      dispatch(commonActions.setDashboardData({ ...dashboard, active: isActive }));
    }
  };

  return (
    <div className='transaction-table-switch'>
      <Button
        disabled={false}
        className='show-active-btn'
        onClick={onSwitch(true)}
        variant={dashboard?.active ? 'dark' : 'outline-dark'}
      >
        {t('reg.dashboard.active')}
      </Button>
      <Button
        disabled={false}
        className='show-closed-btn'
        onClick={onSwitch(false)}
        variant={dashboard?.active ? 'outline-dark' : 'dark'}
      >
        {t('reg.dashboard.closed')}
      </Button>
    </div>
  );
};

export default Switch;
