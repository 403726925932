import {
  TAppConfig,
  CommonActions,
  IDocRequiredFields,
  TDashboard,
} from 'store/features/common/types';

export const setDocRequiredFields = (value: IDocRequiredFields) => ({
  type: CommonActions.setDocRequiredFields,
  value,
});

export const setDefaultTransactionCode = (value: string) => ({
  type: CommonActions.setDefaultTransactionCode,
  value,
});

export const setDashboardData = (value: TDashboard) => ({
  type: CommonActions.setDashboardData,
  value,
});

export const setLoading = (value: boolean) => ({
  type: CommonActions.setLoading,
  value,
});

export const setAppConfig = (value: TAppConfig) => ({
  type: CommonActions.setAppConfig,
  value,
});
