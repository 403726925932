import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseLogo } from 'images/close.svg';
import StyledText from 'components/styledText/StyledText';
import { KEY_CODE } from 'constants/Static';
import { IStyledText } from 'types';

const ErrorMessages = (props: { close: Function; message: IStyledText[] }) => {
  const { t } = useTranslation();
  const { close, message } = props;
  const modalFocus = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalFocus.current) {
      modalFocus.current.focus();
    }
  }, []);

  const enterPressed = (event: any) => {
    const trCode = event.keyCode || event.which;
    if (trCode === KEY_CODE.ENTER) {
      close(false);
    }
  };

  return (
    <div className='modal-wrapper'>
      <div className='modal-header'>
        <div className="dashboard-modal-title" ref={modalFocus}>
          <p>{t('reg.dashboard.modal.new-transaction-review')}</p>
        </div>
        <div
          className=" button close-modal-button"
          role="button"
          onKeyDown={(e) => enterPressed(e)}
          onClick={() => close(false)}
          tabIndex={0}
        >
          <CloseLogo />
        </div>
      </div>
      <div className='modal-body'>
        <div className="no-transaction-found">
          <div>
            <StyledText data={message} isTranslated={true}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessages;
