import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Table from 'components/table/Table';
import columns from 'constants/TableColumns';
import { IRootState } from 'store/types';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { getSelectedDataset, translateColumnsHeaders } from 'helper';
import { TDataset } from 'store/features/agentManagement/types';
import * as agentActions from 'store/features/agentManagement/actions';

const Dataset = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const datasets = state.agent?.datasets || [];

  const clickHandler = (selectedDataset: TDataset) => {
    const datasets = state.agent?.datasets.reduce((acc: TDataset[], dataset: TDataset) => {
      acc.push({ ...dataset, selected: selectedDataset.id === dataset.id });
      return acc;
    }, []) || [];
    if (state.agent) {
      dispatch(agentActions.setAgent({ ...state.agent, datasets }));
    }
  };

  const data = datasets?.map((dataset: TDataset) => {
    const isSelected = dataset.id === getSelectedDataset(state.agent?.datasets)?.id;
    return {
      ...dataset,
      setDefault: <SingleButton {...{
        textButton: 'reg.dataset.set-as-default',
        isActive: isSelected,
        onClick: () => clickHandler(dataset),
        radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
        variant: BUTTON_STYLE.SECONDARY,
      }}
      />,
      isActive: isSelected,
    };
  });

  return (
    <div className="dataset-container">
      <Table data={data} columns={translateColumnsHeaders(columns.defaultDataset, t)} />
    </div>
  );
};

export default Dataset;
