import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const ToolTipField= (props: {
  children: any;
  tooltipValue?: React.ReactElement;
  placement?: 'top'|'right'|'bottom'|'left';
  className?: string;
})=> {
  const { children, tooltipValue, placement, className } = props;

  return (
    <OverlayTrigger
      key={`tooltipValue_${placement}`}
      placement={ placement || 'top' }
      delay={{ show: 20, hide: 200000 }}
      overlay={
        tooltipValue
          ? <Tooltip
            className={className}
            id={`tooltip-${placement}`}
          >
            <div>
              {tooltipValue}
            </div>
          </Tooltip> : <></>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default ToolTipField;
