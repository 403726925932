import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Dataset from 'pages/dataset/Dataset';
import { ReactComponent as VerticalDots } from 'images/verticalDotsIcon.svg';
import { BUTTON_STYLE } from 'constants/Static';
import { IRootState } from 'store/types';
import { ISingleButtonProps } from 'types';
import SingleButton from 'components/buttonTypes/SingleButton';
import { ERoutePaths } from 'enums';

const DatasetContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const state = useSelector((rootState: IRootState) => rootState);
  const name = state.agent?.info?.name || '';
  const datasets = state.agent?.datasets || [];

  const BigButtonProps: ISingleButtonProps = {
    textButton: 'reg.dataset.use-the-default',
    onClick: () => navigate(ERoutePaths.dashboard),
    sizeButton: BUTTON_STYLE.BIG,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
    isActive: !!datasets?.length,
    isDisabled: !datasets?.length,
  };

  return (
    <div className="datasets-container">
      <div className="datasets-content">
        <div className="datasets-text-container top">
          <p className="title">{`${t('reg.dataset.title.hi')} ${name}`}</p>
          <p className="default-transaction">{t('reg.dataset.default-transaction')}</p>
          <p className="offer-text">{t('reg.dataset.offer-text')}</p>
        </div>
        <Dataset />
        <div className="datasets-text-container bottom">
          <p>
            <span>{t('reg.dataset.change-the-default')}</span>
            <VerticalDots />
            <span>{t('reg.dataset.settings-application')}</span>
          </p>
        </div>
        {!datasets?.length &&
        <h4 className='missing-dataset-error'>
          {t('reg.dataset.switchDataset.error')}
        </h4>}
      </div>
      <div className="button-container">
        <SingleButton {...BigButtonProps} />
      </div>
    </div>
  );
};

export default DatasetContainer;
