import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Switch from 'pages/dashboard/Table/Switch';
import Pagination from 'pages/dashboard/Table/Pagination';
import Table from 'components/table/Table';
import { IRootState } from 'store/types';
import columns from 'constants/TableColumns';
import { getSelectedDataset, translateColumnsHeaders } from 'helper';
import * as commonActions from 'store/features/common/actions';

const Transactions = (props: { getTransactions: Function }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const defaultDataset = getSelectedDataset(state.agent?.datasets);
  const dashboard = state?.common?.dashboard;
  const tableColumns = dashboard?.active ? columns?.active : columns?.closed;
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchValue('');
    props.getTransactions();
  }, [searchValue, defaultDataset, dashboard?.active, dashboard?.page]);

  useEffect(() => {
    if (searchValue) {
      const filteredData = dashboard?.items?.filter((item: Record<string, any>) => (
        Object.values(item).some((value: string) => (
          typeof value === 'string' && value?.toLowerCase().includes(searchValue?.toLowerCase())
        ))
      ));
      if (filteredData && dashboard) {
        dispatch(commonActions.setDashboardData({
          ...dashboard,
          items: filteredData,
        }));
      }
    }
  }, [searchValue]);

  const onChange = (page: number) => {
    if (dashboard) {
      dispatch(commonActions.setDashboardData({ ...dashboard, page }));
    }
  };

  return (
    <div className='transactions-table'>
      <Table
        data={dashboard?.items || []}
        showSearch={true}
        loading={state?.common?.loading}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        columns={translateColumnsHeaders(tableColumns, t)}
        customHeader={<Switch />}
        customFooter={<Pagination onChange={onChange}/>}
      />
    </div>
  );
};

export default Transactions;