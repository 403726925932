import React from 'react';

const DocumentValidation = () => {
  return (
    <p className="description-text">
      DocumentValidation
    </p>
  );
};

export default DocumentValidation;
