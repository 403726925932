import React, { useRef, useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { getImageFromBase64 } from 'util/common';
import Loader from 'components/loader/Loader';
import { useTranslation } from 'react-i18next';
import { getNotFoundImage } from 'helper';
import { GetResourceAPI } from 'helper/api/route';
import { LOADING_DURATION } from 'constants/Static';

interface IImageWrapper {
  scale: number;
  transactionCode: string;
  filetype: string;
  path: string;
}

const ImageWrapper = (props: IImageWrapper) => {
  const { i18n } = useTranslation();
  const { scale, transactionCode, filetype, path } = props;
  const containerRef = useRef() as React.MutableRefObject<HTMLImageElement>;
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [imageNotFound, setImageNotFound] = useState(false);
  const [image, setImage] = useState('');

  const setupZoom = (rest: any) => {
    rest.doubleClick.disabled = true;
    rest.pan.paddingSize = 0;
    rest.options.minScale = 1;
    rest.options.maxScale = 3;
  };

  const onLoad = (
    setPositionX: Function,
    setPositionY: Function,
    setDefaultState: Function
  ) => {
    setDefaultState();
    setPositionX(0, 0, 'easeOut');
    setPositionY(0, 0, 'easeOut');
    setImageLoaded(true);
  };

  useEffect(() => {
    if (transactionCode) {
      GetResourceAPI(transactionCode, filetype).then((response) => {
        if (response.data) {
          setImage(response.data.content);
        } else {
          const interval = setInterval(() => {
            if (!isImageLoaded && window.location.pathname === path) window.location.reload();
            clearInterval(interval);
          }, LOADING_DURATION);
        }
      }).catch(() => {
        setImageLoaded(true);
        setImageNotFound(true);
      });
    } else {
      setImageNotFound(true);
    }
  }, []);

  console.log(imageNotFound);
  

  return (<>
    <div className="wrapper-transform-component">
      <TransformWrapper defaultScale={1} scale={scale}>
        {({
          wheel,
          setScale,
          setPositionX,
          setPositionY,
          setDefaultState,
          positionX,
          positionY,
          ...rest
        }: any) => {
          wheel.disabled = true;
          setupZoom(rest);
          return (
            <div className='transform-component'>
              <div className="wrapper-image-container" ref={containerRef}>
                {image && !imageNotFound && isImageLoaded && <div className="loader-container"><Loader /></div>}
                <TransformComponent>
                  <img
                    src={image
                      ? getImageFromBase64(image) : getNotFoundImage(i18n.language) }
                    onLoad={() => onLoad(setPositionX, setPositionY, setDefaultState)}
                    loading="lazy"
                    alt="transform-component"
                  />
                </TransformComponent>
              </div>
            </div>
          );
        }}
      </TransformWrapper>
    </div>
  </>);
};

export default ImageWrapper;
