import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as CloseLogo } from 'images/close.svg';
import { ReactComponent as SearchIcon } from 'images/search.svg';
import { KEY_CODE } from 'constants/Static';
import { IRootState } from 'store/types';
import { getSelectedDataset } from 'helper';

const SearchTransaction = (props: {
  close: Function;
  startReview: Function;
}) => {
  const { t } = useTranslation();
  const { close, startReview } = props;
  const state = useSelector((rootState: IRootState) => rootState);
  const [searchInput, setSearchInput] = useState('');
  const selectedDataset = getSelectedDataset(state.agent?.datasets);
  const [isTransactionFound, setIsTransactionFound] = useState(false);

  const handleSearch = () => {
    if (!searchInput) return;
    startReview({ transactionCode: searchInput });
    close(false);
  };

  const enterPressed = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>,
    type: string,
  ) => {
    const code = event.keyCode || event.which;
    if (code === KEY_CODE.ENTER) {
      switch (type) {
      case 'close':
        close(false);
        break;
      case 'enter':
        if (isTransactionFound) {
          handleSearch();
        } else {
          setIsTransactionFound(true);
        }
        break;
      default:
        break;
      }
    }
  };

  const SearchResultFound = () => (
    <div
      className="search-success"
      tabIndex={0}
      onKeyDown={handleSearch}
    >
      <div className="review-date" />
      <div className="brand">
        <span>{selectedDataset?.name || ''}</span>
      </div>
      <div className="client">
        <span>{selectedDataset?.title || ''}</span>
      </div>
      <div className="tid">
        <span>{searchInput}</span>
      </div>
      <div className="reasons" />
      <div className="review">
        <span
          onClick={handleSearch}
          role="button"
          tabIndex={-1}
          className={'text'}
        >
          {t('reg.search.result.review')}
        </span>
      </div>
    </div>
  );

  return (
    <div className='modal-wrapper'>
      <div className='modal-header'>
        <div className="dashboard-modal-title" tabIndex={-1}>
          <p>{t('reg.dashboard.modal.search.transaction')}</p>
          <div className="search-input">
            <input
              className="trcode-input"
              autoFocus
              type="text"
              inputMode="numeric"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value.trim())}
              onKeyDown={(e) => enterPressed(e, 'enter')}
            />
            <SearchIcon />
          </div>
        </div>
        <div
          className=" button close-modal-button"
          role="button"
          tabIndex={0}
          onClick={() => close(false)}
          onKeyDown={(e) => enterPressed(e, 'close')}
        >
          <CloseLogo />
        </div>
      </div>
      <div className='modal-body'>
        {(searchInput && isTransactionFound && <SearchResultFound />)}
      </div>
    </div>
  );
};

export default SearchTransaction;
