import { getStorageData } from 'util/storageHelper';
import { IReviewResult } from 'store/features/reviewResult/types';

export default (): IReviewResult => {
  const data: string | null = getStorageData('state');
  const storageData = data ? JSON.parse(data) : {};
  return {
    transactionCode: storageData.transactionCode || '',
    transactionData: storageData.transactionData || null,
    manualCheckResult: storageData.manualCheckResult || null,
  };
};
