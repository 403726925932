import axios from 'util/axios';

import { TCompareFields } from 'types';
import { TManualCheckResult } from 'store/features/reviewResult/types';

export const SignInAPI = (email: string, password: string, turnstileToken: string) => (
  axios.post('/login', { email, password, turnstileToken })
);

export const GetSpecimensAPI = (documentType: string, country: string) => (
  axios.get('/specimens', { params: { documentType, documentCountry: country }})
);

export const GetTransactionDataAPI = (transactionCode: string) => (
  axios.get(`/transaction-data/${transactionCode}`)
);

export const LockTransactionAPI = (data: { transactionCode?: string, orderId?: string }) => (
  axios.put('/lock', data)
);

export const ExportFileAPI = (totalItems: number) => (
  axios.get('/export', { params: { totalItems }})
);

export const GetTransactionsAPI = (params: {
  limit: number,
  offset: number,
  reviewed: boolean,
  datasetId: number | undefined,
}) => (
  axios.get('/transactions', { params })
);

export const GetRequiredFieldsAPI = (type: string) => (
  axios.get('/required-fields', { params: { type } })
);

export const CompleteTransactionAPI = (transactionCode: string, data: TManualCheckResult) => (
  axios.put(`/complete/${transactionCode}`, { data })
);

export const SendEmail = (data: any) => axios.post('/send-email', { data });

export const GetAppConfigsAPI = () => axios.get('/configs');

export const UnlockTransactionAPI = (transactionCode: string) => (
  axios.put(`/unlock/${transactionCode}`)
);

export const LogErrorAPI = (message: string) => (axios.post('/log', message));

export const GetResourceAPI = (transactionCode: string, resource: string) => (
  axios.get(`/recourses/${transactionCode}`, { params: { resource } })
);

export const CompareFieldsAPI = (transactionCode: string, data: TCompareFields) => (
  axios.post(`/compare-fields/${transactionCode}`, data)
);

export const ForgotPasswordAPI = (email: string, turnstileToken: string) => (
  axios.post('/reset-password', { email, turnstileToken })
);

export const SetNewPasswordAPI = (
  agentId: number,
  password: string,
  activationToken: string,
  turnstileToken: string,
) => (
  axios.post(`/set-password/${agentId}`, { password, activationToken, turnstileToken })
);

export const ValidateTokenAPI = (activationToken: string) => (
  axios.post('/validate-token', { activationToken })
);