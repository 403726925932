import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from 'images/pxlCheck.svg';
import Input from 'components/input/Input';
import { ForgotPasswordAPI } from 'helper/api/route';
import { ISingleButtonProps } from 'types';
import Loader from 'components/loader/Loader';
import SwitchLanguage from 'components/lang/SwitchLanguage';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import TurnstileWidget from 'components/turnstile/TurnstileWidget';
import { isValidEmail } from 'helper/validation';
import { ERoutePaths } from 'enums';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const validated = isValidEmail(email);
  const screenIsApproved = Boolean(validated && turnstileToken);

  const clickHandler = () => {
    if (loader || !screenIsApproved) return;
    setLoader(true);
    ForgotPasswordAPI(email, turnstileToken)
      .then(() => {
        setLoader(false);
        navigate(ERoutePaths.signIn);
      })
      .catch(() => {
        setLoader(false);
        setErrorMessage('messages.invalid-request');
      });
  };

  const EmailInput = {
    className: 'input-container',
    handler: (value: string) => {
      setEmail(value);
      setTurnstileToken('');
      if (!isValidEmail(value)) {
        setErrorMessage('forgotten-password.error-message.email');
      } else {
        setErrorMessage('');
      }
    },
    placeholder: t('forgotten-password.email.title'),
    type: 'text',
    label: t('forgotten-password.email.title'),
  };

  const buttonPropsData: ISingleButtonProps = {
    isActive: screenIsApproved && !loader,
    isDisabled: !screenIsApproved || loader,
    onClick: clickHandler,
    textButton: 'forgotten-password.btn',
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
  };

  return (
    <div className="main-content">
      <div className="container">
        <div className="login-container forgot-container">
          <div className="identity-container">
            <Logo />
            <span className="identity-text">{t('sign-in.header')}</span>
          </div>
          <div className="form-container">
            <span className="forgotten-heading">{t('forgotten-password.heading')}</span>
            <div className="forgotten-error-container">{email ? t(errorMessage) : ''}</div>
            { Input(EmailInput) }
            <p className='forgotten-info-message'>{t('forgotten-password.message1')}</p>
            {validated &&
              <TurnstileWidget
                setToken={setTurnstileToken}
                setErrorMessage={setErrorMessage}
                turnstileToken={turnstileToken}
              />
            }
            <div className="button-container">
              <SingleButton {...buttonPropsData} />
              {loader && <div className="loader-container"><Loader /></div>}
            </div>
          </div>
          <div>
            <hr className="horizontal-line" />
            <div className="signin-from">
              <span>{t('sign-in.have-account')} </span>
              <Link to={ERoutePaths.signIn}>{t('sing-in.link')}</Link>
            </div>
          </div>
        </div>
      </div>
      <SwitchLanguage />
    </div>
  );
};

export default ForgotPassword;
