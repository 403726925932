import { EFileTypes, EReviewStates, EZones } from 'enums';
import { TReturnCodes } from 'types';

export enum ReviewResultActions {
  setTransactionData = 'SET_TRANSACTION_DATA',
  setTransactionCode = 'SET_TRANSACTION_CODE',
  setManualCheckResult = 'SET_MANUAL_CHECK_RESULT',
}

export type TResultItem = {
  result: string;
};

export interface IResultFile {
  filetype: EFileTypes;
  filename: string;
  SHA512: string;
}

export type TManualCheckFilesItem = {
  files: IResultFile[];
};

export type TDevice = {
  model: string;
  osVersion: string;
}

export interface IAddress {
  street?: string;
  houseNumber?: string;
  zipCode?: string;
  city?: string;
  countryCode?: string;
}

export interface IPersonalDetails {
  phoneNumber?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  maidenName?: string;
  dateOfBirth?: string;
  address?: IAddress;
  nationality?: string;
  gender?: string;
}

export type TUserAuthentication = {
  channel?: string;
  phoneNumber?: string;
  emailAddress?: string
};

export type TDocumentVerificationFields = {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  placeOfBirth?: string;
  authority?: string;
  documentType?: string;
  documentNumber?: string;
  documentCountry?: string;
  nationality?: string;
  gender?: string;
  expirationDate?: string;
  dateOfIssue?: string;
}

export type TDocumentVerification = {
  files: IResultFile[];
  documentTypeClassifier?: {
    classifiedDocumentFirstScan?: string;
    classifiedDocumentSecondScan?: string;
  };
  mrz?: TDocumentVerificationFields;
  viz?: TDocumentVerificationFields;
  barcode?: TDocumentVerificationFields;
  nfc?: TDocumentVerificationFields;
  calculatedAge?: number;
};

export type TLivenessFaceVerification = {
  result: string;
  threshold: number;
  confidenceLevel: number;
  processId: string;
  FSTID: string;
}

export type TFaceLiveness = {
  files: Array<IResultFile>;
  livenessCheck?: TLivenessFaceVerification;
  faceVerification?: TLivenessFaceVerification;
};

export type ITAddressCoordinates = {
  x: number;
  y: number;
  width: number;
  height: number;
  unit: string;
}

export type TAddressCheck = {
  files?: IResultFile[];
  addressCoordinates?: ITAddressCoordinates;
  address?: {
    addressLine2: string;
    street: string;
    houseNumber: string;
    zipCode: string;
    city: string;
    countryCode: string;
  };
  firstName?: string;
  lastName?: string;
  maidenName?: string;
  SettlementId?: number;
  CorrectionType?: string;
  TotalScore?: string;
};

export type TRejectionReasons = {
  imageQuality: string[];
  document: string[];
  documentRecording: string[];
  faceVerification: string[];
  livenessDetection: string[];
  addressReview: string[];
  a4ScanReview: string[];
  externalVerification: string[];
}

type EZonesSubset = EZones.viz | EZones.mrz;

export type TDataVerification = {
  [key in EZonesSubset]?: {
    [key: string]: {
      value: string;
      edited: boolean;
    };
  };
}

export type TDocumentValidationReview = {
  result: string;
  documentCountry: string;
  documentType: string;
  extraDetail: string;
  dataVerification: TDataVerification[];
};

export type TManualCheckResult = {
  result?: EReviewStates;
  agentIdentifier?: string;
  message?: string;
  postProcessing?: boolean;
  rejectionReasons?: TRejectionReasons;
  identityReview?: {
    documentValidationReview?: TDocumentValidationReview[];
    faceVerificationReview?: TResultItem;
    livenessDetectionReview?: TResultItem;
  };
  addressReview?: TResultItem;
  a4ScanReview?: TResultItem;
};

export type TTransactionData = {
  flaggedAsFraud: boolean;
  modules: string[];
  device: TDevice;
  minimumAge?: number;
  initialPersonalData?: IPersonalDetails;
  userAuthentication?: TUserAuthentication;
  selfDeclaration?: IPersonalDetails;
  returnCodes?: TReturnCodes;
  documentVerification?: TDocumentVerification[];
  idRecording?: TManualCheckFilesItem[];
  faceLiveness?: TFaceLiveness;
  addressCheck?: TAddressCheck;
  a4Scan?: TManualCheckFilesItem[];
  manualCheck?: TManualCheckResult[];
};

export interface IReviewResult {
  transactionCode: string;
  transactionData: TTransactionData;
  manualCheckResult: TManualCheckResult;
}

export type TReviewResultActions =
  {
    type: ReviewResultActions.setTransactionData;
    value: { transactionData: TTransactionData; transactionCode: string; }
  } | { type: ReviewResultActions.setManualCheckResult; value: TManualCheckResult };
