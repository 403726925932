import React from 'react';
import { Navigate } from 'react-router-dom';
import Loader from 'components/loader/Loader';
import { ERoutePaths } from 'enums';
import { isAuthorized } from 'util/authService';

const ConditionalRedirect = (props: { redirectUrl: string }) => {
  const { redirectUrl } = props;

  if (!isAuthorized()) {
    return <Navigate to={ERoutePaths.signIn} />;
  }

  if (redirectUrl) {
    return <Navigate to={redirectUrl} />;
  }

  return <div className="loader-container"><Loader /></div>;
};

export default ConditionalRedirect;
