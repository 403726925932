import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';

import MultipleSelect from 'components/select/MultipleSelect';
import { EAnswers, EFileTypes, EStatuses } from 'enums';
import { IRootState } from 'store/types';
import { IOptionType } from 'types';
import { ReactComponent as Info } from 'images/info.svg';
import ToolTip from 'components/toolTipField/ToolTipField';
import { GetResourceAPI } from 'helper/api/route';
import { useLocation } from 'react-router-dom';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';

const RejectionReasons = [
  {
    'label': 'identity.fv.rejection-reason-1.title',
    'value': 'reason-1'
  },
  {
    'label': 'identity.fv.rejection-reason-2.title',
    'value': 'reason-2'
  },
  {
    'label': 'identity.fv.rejection-reason-3.title',
    'value': 'reason-3'
  }
]

const statusMessages = {
  [EStatuses.pass]: 'identity.fv.result.label.pass',
  [EStatuses.fail]: 'identity.fv.result.label.fail',
}

const FaceVerification = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionData, transactionCode } = state.reviewResult;
  const [guidelinesAccepted1, setGuidelinesAccepted1] = React.useState(false);
  const [guidelinesAccepted2, setGuidelinesAccepted2] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const { faceLiveness } = transactionData;
  const { faceVerification } = faceLiveness || {};
  const result = EStatuses[faceVerification?.result as keyof typeof EStatuses] || EStatuses.pass;
  const [reasons, setReasons] = useState<IOptionType[]>([]);


  const rejectionReasonsProps = {
    value: reasons,
    handler: (selected: IOptionType[]) => setReasons(selected),
    placeholder: t('identity.fv.rejection-reason-placeholder'),
    options: RejectionReasons.map((reason: IOptionType) => ({
      label: t(reason.label),
      value: reason.value,
    })),
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === EAnswers.yes) {
      setReasons([]);
      setSelectedOption(EAnswers.yes);
    } else {
      setSelectedOption(EAnswers.no);
    }
  }

  return (
    <div className="face-verification-container">
      <p className="face-verification-title">{t('identity.fv.heading')}</p>
      <div className='face-verification-main'>
        <div className="face-verification-section">
          <ImageWrapper
            scale={1}
            transactionCode={transactionCode}
            filetype={EFileTypes.faceAvatar}
            path={location.pathname}
          />
        </div>
        <div className="review-section">
          <p className="face-verification-status">
            {t('identity.fv.result.title')}
            <span className={result}>{t(statusMessages[result])}</span></p>
          <div className="line"></div>
          <div className="review">
            <span className='review-text'>{t('identity.fv.guideline.heading')}</span>
            <div className='checkbox-section'>
              <input
                type="checkbox"
                checked={guidelinesAccepted1}
                onChange={(event) => setGuidelinesAccepted1(event.target.checked)}
              />
              <p className='review-description'>
                {t('identity.fv.guideline.message1')}
                <ToolTip
                  placement='top'
                  tooltipValue={<Trans i18next='identity.fv.guideline.message1.tooltip'/>}
                >
                  <Info />
                </ToolTip>
              </p>
            </div>
            <div className='checkbox-section'>
              <input
                type="checkbox"
                checked={guidelinesAccepted2}
                onChange={(event) => setGuidelinesAccepted2(event.target.checked)}
              />
              <p className='review-description'>
                {t('identity.fv.guideline.message2')}
                <ToolTip
                  placement='top'
                  tooltipValue={<Trans i18next='identity.fv.guideline.message2.tooltip'/>}
                >
                  <Info />
                </ToolTip>
              </p>
            </div>
            <div className="options">
            </div>
          </div>
          {
            guidelinesAccepted1 && guidelinesAccepted2 ? <>
              <div className="line"></div>
              <div className="review-buttons">
                <h2 className="description">{t('identity.fv.question.title')}</h2>
                <div className='answer-section'>
                  <Form.Check
                    inline
                    label={t('identity.fv.answer-yes.title')}
                    value={EAnswers.yes}
                    name='identity.fv.answer'
                    className='radio-button'
                    onChange={handleChange}
                    type='radio'
                  />
                  <Form.Check
                    inline
                    label={t('identity.fv.answer-no.title')}
                    value={EAnswers.no}
                    name='identity.fv.answer'
                    className='radio-button'
                    onChange={handleChange}
                    type='radio'
                  />
                </div>
                {
                  selectedOption === 'no' ? <div className='select-reasons'>
                    <MultipleSelect {...rejectionReasonsProps} />
                  </div> : null
                }
              </div>
            </> : null
          }
        </div>
      </div>
    </div>
  );
};

export default FaceVerification;
