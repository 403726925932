const config = {
  closed: [
    {
      Header: 'reg.dashboard.history.header',
      columns: [
        {
          Header: 'reg.dashboard.history.date',
          accessor: 'completedTime',
        },
        {
          Header: 'reg.dashboard.history.tid',
          accessor: 'transactionCode',
        },
        {
          Header: 'reg.dashboard.history.status',
          accessor: 'transactionState',
        },
        {
          Header: 'reg.dashboard.history.review-date',
          accessor: 'reviewedTime',
        },
        {
          Header: 'reg.dashboard.history.reviewed-by',
          accessor: 'reviewedBy',
        },
        {
          Header: 'reg.dashboard.history.comments',
          accessor: 'comments',
        },
      ],
    },
  ],
  active: [
    {
      Header: 'reg.dashboard.history.header',
      columns: [
        {
          Header: 'reg.dashboard.active.date',
          accessor: 'completedTime',
        },
        {
          Header: 'reg.dashboard.active.tid',
          accessor: 'transactionCode',
        },
        {
          Header: 'reg.dashboard.active.status',
          accessor: 'transactionState',
        },
      ],
    },
  ],
  switchDataset: [
    {
      Header: 'reg.dataset.switchDataset.columns.customer',
      accessor: 'name',
    },
    {
      Header: 'reg.dataset.switchDataset.columns.application',
      accessor: 'title',
    },
    {
      Header: '',
      accessor: 'switchTo',
    },
  ],
  defaultDataset: [
    {
      Header: 'reg.dataset.defaultDataset.header',
      columns: [
        {
          Header: 'reg.dataset.defaultDataset.columns.customer',
          accessor: 'name',
        },
        {
          Header: 'reg.dataset.defaultDataset.columns.application',
          accessor: 'title',
        },
        {
          Header: '',
          accessor: 'setDefault',
        },
      ],
    },
  ],
};

export default config;
