import produce from 'immer';

import { setStorageDataItemByKey } from 'util/storageHelper';
import {
  IReviewResult,
  ReviewResultActions,
  TReviewResultActions,
} from 'store/features/reviewResult/types';
import getInitialState from 'store/features/reviewResult/initialState';

export const reviewResultReducer = (
  state: IReviewResult = getInitialState(), action: TReviewResultActions,
) => {
  const nextState = produce(state, (draftState: IReviewResult) => {
    switch (action.type) {
    case ReviewResultActions.setTransactionData:
      draftState.transactionCode = action.value.transactionCode;
      draftState.transactionData = action.value.transactionData;
      setStorageDataItemByKey('transactionCode', action.value.transactionCode);
      setStorageDataItemByKey('transactionData', action.value.transactionData);
      break;
    case ReviewResultActions.setManualCheckResult:
      draftState.manualCheckResult = action.value;
      setStorageDataItemByKey('manualCheckResult', action.value);
      break;
    }
    return draftState;
  });

  return nextState;
};
