import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseLogo } from 'images/close.svg';
import Table from 'components/table/Table';
import columns from 'constants/TableColumns';
import { IRootState } from 'store/types';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { getSelectedDataset, translateColumnsHeaders } from 'helper';
import { TDataset } from 'store/features/agentManagement/types';
import * as agentActions from 'store/features/agentManagement/actions';

const SwitchDataset = (props: { close: Function }) => {
  const { t } = useTranslation();
  const { close } = props;
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const modalFocus = useRef<HTMLDivElement>(null);
  const datasets = state.agent?.datasets || [];

  useEffect(() => {
    if (modalFocus.current) {
      modalFocus.current.focus();
    }
  }, []);

  const clickHandler = (selectedDataset: TDataset) => {
    const datasets = state.agent?.datasets.reduce((acc: TDataset[], dataset: TDataset) => {
      acc.push({ ...dataset, selected: selectedDataset.id === dataset.id });
      return acc;
    }, []) || [];
    if (state.agent) {
      dispatch(agentActions.setAgent({ ...state.agent, datasets }));
      close(false);
    }
  };

  const data = datasets?.map((dataset: TDataset) => {
    const isSelected = dataset.id === getSelectedDataset(state.agent?.datasets)?.id;
    return {
      ...dataset,
      switchTo: !isSelected ? <SingleButton {...{
        textButton: 'reg.dataset.button.switch-to',
        isActive: isSelected,
        onClick: () => clickHandler(dataset),
        radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
        variant: BUTTON_STYLE.SECONDARY,

      }} /> : <></>,
      isActive: isSelected
    };
  });

  return (
    <div className='modal-wrapper'>
      <div className='modal-header'>
        <div
          className="dashboard-modal-title switch-dataset-modal-title"
          ref={modalFocus} tabIndex={0}
        >
          <p>{t('reg.dashboard.modal.available.accounts')}</p>
        </div>
        <div
          className=" button close-modal-button"
          role="button"
          onClick={() => close(false)}
          tabIndex={0}
        >
          <CloseLogo />
        </div>
      </div>
      <div className="modal-body switch-dataset-modal">
        <Table data={data} columns={translateColumnsHeaders(columns.switchDataset, t)} />
      </div>
    </div>
  );
};

export default SwitchDataset;