import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IRootState } from 'store/types';
import { ExportFileAPI } from 'helper/api/route';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { ReactComponent as DownloadButtonIcon } from 'images/download.svg';
import { downloadFile } from 'util/common';
import { AxiosResponse } from 'axios';

interface IPaginationProps {
  onChange: (page: number) => void;
}

const TablePagination = (props: IPaginationProps) => {
  const { onChange } = props;
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const dashboard = state?.common?.dashboard;
  const { page = 1, size = 0, totalItems = 0 } = dashboard || {};
  const totalPages = Math.ceil(totalItems / size) || 1;

  const exportCSV = () => {
    ExportFileAPI(totalItems).then((response: AxiosResponse) => {
      downloadFile(response.data);
    });
  };

  const startIndex = (page - 1) * size + 1;
  const endIndex = page !== totalPages ? page * size : totalItems;

  return (
    <>
      {totalItems ? (
        <div className='transactions-table-pagination'>
          {!dashboard?.active ? <div className="export-button-container">
            <SingleButton
              onClick={exportCSV}
              textButton={'reg.table.export-reviewed'}
              sizeButton={BUTTON_STYLE.BIG}
              radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
              variant={BUTTON_STYLE.OUTLINE_PRIMARY}
              LeftIcon={DownloadButtonIcon}
            />
          </div> : null}
          <Pagination>
            <Pagination.Item disabled className="displayText">
              {`${t('reg.table.pagination.text.displaying')}
              ${startIndex}-${endIndex} ${t('reg.table.pagination.text.outOf')} ${totalItems}`}
            </Pagination.Item>
            <div className="btnCollection">
              <Pagination.Prev
                className={`bttArrow ${startIndex > 1 ? 'active' : ''}`}
                onClick={() => onChange(page - 1)}
                disabled={startIndex === 1}
              />
              <Pagination.Next
                className={`bttArrow ${endIndex !== totalItems ? 'active' : ''}`}
                onClick={() => onChange(page + 1)}
                disabled={endIndex === totalItems}
              />
            </div>
          </Pagination>
        </div>)
        : null}
    </>
  );
};

export default TablePagination;
