import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TopHeader from 'components/header/TopHeader';
import { isAuthorized } from 'util/authService';
import { getLocalStorageData } from 'util/storageHelper';
import { ERoutePaths } from 'enums';

const PrivateRoute = (props: { component: React.ReactElement }) => {
  const navigate = useNavigate();
  const agent: string | null = getLocalStorageData('agent');

  useEffect(() => {
    if (!isAuthorized()) {
      navigate(agent ? ERoutePaths.logOut : ERoutePaths.signIn);
    }
  }, []);

  return (
    <>
      <TopHeader />
      <div className="content">{props.component}</div>
    </>
  );
};

export default PrivateRoute;
