import {
  TTransactionData,
  TManualCheckResult,
  ReviewResultActions,
} from 'store/features/reviewResult/types';

export const setTransactionData = (value: {
  transactionData: TTransactionData; transactionCode: string;
}) => ({
  type: ReviewResultActions.setTransactionData,
  value,
});

export const setManualCheckResult = (value: TManualCheckResult) => ({
  type: ReviewResultActions.setManualCheckResult,
  value,
});
