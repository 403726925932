import moment from 'moment';
import { TFunction } from 'react-i18next';

import { t } from 'i18n/i18n.config';
import { ISpecimen, IUserAgent } from 'types';
import { EDocumentType, EField, ETransactionState } from 'enums';
import { TDataset } from 'store/features/agentManagement/types';
import missingSpecimenEN from 'images/missing_specimen_EN.png';
import missingSpecimenDE from 'images/missing_specimen_DE.png';
import serviceUnavailableEN from 'images/service_unavailable_EN.png';
import serviceUnavailableDE from 'images/service_unavailable_DE.png';
import Countries from 'constants/Countries.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import { SOMETHING } from 'constants/Static';

const getFormattedValue = (fieldType: string, fieldValue: string, translate: Function) => {
  if (!fieldValue) return '';
  if (fieldType === EField.expirationDate
    || fieldType === EField.dateOfBirth
    || fieldType === EField.dateOfIssue
    || fieldType === EField.birthdate
  ) {
    return moment(fieldValue, 'YYYY-MM-DD').format('DD.MM.YYYY');
  }
  if (fieldType === EField.documentType) {
    const docType = DocumentTypes.find((option) => option.value === fieldValue);
    if (docType?.label) return translate(docType.label);
  }
  return fieldValue;
};

const translateColumnsHeaders = (
  columns: any,
  translate: Function,
) => (columns.map((item: any) => {
  const updatedColumns = item.columns?.map((element: any) => (
    {
      ...element,
      Header: translate(element.Header),
    }
  )) || [];
  return {
    ...item,
    Header: translate(item.Header),
    ...(item.columns && { columns: updatedColumns }),
  };
}));

const translateDocTypeCountry = (list: string[], translate: Function) => (
  list.reduce((result: string, word: string, index: number) => {
    result += translate(word);
    if (index !== list.length - 1) {
      result += ' ';
    }
    return result;
  }, '')
);

const getErrorMessage = (transactionState: string, transactionCode: string) => {
  switch (transactionState) {
  case ETransactionState.reviewed:
  case ETransactionState.checkCompleted:
  case ETransactionState.pxlCheckError:
  {
    return [
      { text: t('messages.transaction') },
      { text: transactionCode, type: 'bold' },
      { text: t('messages.reviewed') },
    ];
  }
  case ETransactionState.inReview: {
    return [
      { text: t('messages.transaction') },
      { text: transactionCode, type: 'bold' },
      { text: t('messages.inReview') },
    ];
  }
  case ETransactionState.deleted: {
    return [{ text: t('messages.deleted') }];
  }
  case ETransactionState.completed: {
    return [
      { text: t('messages.transaction') },
      { text: transactionCode, type: 'bold' },
      { text: t('messages.completed') },
    ];
  }
  case ETransactionState.inError: {
    return [
      { text: t('messages.inError') },
    ];
  }
  default: {
    return !transactionCode ? [{ text: t('reg.dashboard.modal.no-transaction') }]
      : [
        { text: t('messages.transaction') },
        { text: transactionCode, type: 'bold' },
        { text: t('messages.notFound') },
      ];
  }
  }
};

const toTitleCase = (input = '') => (
  input.split('_').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
);

const getFieldTranslationKey = (fieldName: string, translateSource: string) => {
  switch (translateSource) {
  case 'data-check':
    return `field.${fieldName}.title`;
  case 'extracted-data':
  case 'self-declaration':
    return `user-data-check.${translateSource}.field.${fieldName}.title`;
  default:
    return '';
  }
};

const getUserAgent = (): IUserAgent => {
  const ua = navigator.userAgent;
  const isChrome = (/Chrome/i.test(ua));
  const isFirefox = (/Firefox/i.test(ua));
  const isSafari = (/Safari/i.test(ua)) && !(/Chrome/i.test(ua)) && !(/CriOS/i.test(ua));
  return { isChrome, isFirefox, isSafari };
};

const getSelectedDataset = (datasets: TDataset[] | undefined) => (
  datasets?.length ? datasets?.find((dataset: TDataset) => dataset.selected) || datasets[0] : null
);

const getNotFoundImage = (language: string) => {
  switch (language) {
  case 'en':
    return missingSpecimenEN;
  case 'de':
    return missingSpecimenDE;
  default:
    return missingSpecimenEN;
  }
}

const getNotFoundSpecimen = (language: string) => {
  switch (language) {
  case 'en':
    return missingSpecimenEN;
  case 'de':
    return missingSpecimenDE;
  default:
    return missingSpecimenEN;
  }
}

const getServiceUnavailableImage = (language: string) => {
  switch (language) {
  case 'en':
    return serviceUnavailableEN;
  case 'de':
    return serviceUnavailableDE;
  default:
    return serviceUnavailableEN;
  }
}

const getCountryValue = (value: string, t: TFunction) => {
  const countryData = Countries.find((option) => option.value === value);
  if (countryData?.value) {
    return {
      label: `${t(countryData.label)} (${countryData.value})`,
      value: countryData.value,
    };
  }
  if (value) {
    return {
      label: t('reg.files.document-country.unknown'),
      value: SOMETHING,
    };
  }
  return null;
};

const collectExtraDetailOptions = (specimens: ISpecimen[], t: TFunction, includeOther = false) => {
  let result = specimens.map((item: ISpecimen) => ({
    label: toTitleCase(item.extraDetail),
    value: item.extraDetail,
  }));
  if (includeOther) {
    result = result.concat([{
      label: t('reg.your.selection.title.extra-detail.other'),
      value: 'OTHER',
    }]);
  }
  return result.filter((a, i) => (
    result.findIndex((option) => a.label === option.label) === i)
  );
}

const getDocumentTypeValue = (value: string, t: TFunction) => {
  const documentTypeData = DocumentTypes.find((option) => option.value === value);
  if (documentTypeData?.value) {
    return {
      label: t(documentTypeData.label),
      value: documentTypeData.value,
    };
  }
  if (value) {
    return {
      label: t('reg.files.document-country.unknown'),
      value: EDocumentType.something,
    };
  }
  return null;
};

export {
  toTitleCase,
  getUserAgent,
  getErrorMessage,
  getSelectedDataset,
  getFormattedValue,
  getFieldTranslationKey,
  translateDocTypeCountry,
  translateColumnsHeaders,
  getNotFoundImage,
  getNotFoundSpecimen,
  getServiceUnavailableImage,
  getCountryValue,
  getDocumentTypeValue,
  collectExtraDetailOptions,
};
