import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import InfoBar from 'pages/review/infoBar/InfoBar';
import Footer from 'pages/review/footer/Footer';
import IdentityReview from 'pages/identityReview/IdentityReview';
import { getReturnCodeKeys } from 'helper/extractedDataHandling';
import { IRootState } from 'store/types';
import { ERoutePaths } from 'enums';

const getActivePage = (pathname: string) => {
  if (pathname.includes(ERoutePaths.identityReview)) {
    return <IdentityReview />;
  }
  return null;
};

const Review = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionData } = state.reviewResult;

  return (<>
    <InfoBar />
    <div className='manual-check-reasons-section'>
      <p>{t('manual-check-reasons')}
        <span>{getReturnCodeKeys(transactionData?.returnCodes)?.map(
          (key: string) => t(key)).join(', ')
        }</span>
      </p>
      <p>{t('need-some-help')}</p>
    </div>
    <div className='review-content'>
      {getActivePage(location.pathname)}
    </div>
    <Footer />
  </>);
};

export default Review;
