import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ISingleButtonProps } from 'types';
import { IRootState } from 'store/types';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { ReactComponent as BackButton } from 'images/right.svg';
import { ERoutePaths } from 'enums';
import { UnlockTransactionAPI, GetTransactionDataAPI } from 'helper/api/route';
import { translateDocTypeCountry } from 'helper';
import * as reviewResultActions from 'store/features/reviewResult/actions';
import Loader from 'components/loader/Loader';
import InfoBar from 'pages/review/infoBar/InfoBar';
import { getReturnCodeKeys } from 'helper/extractedDataHandling';

const TABoarding = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { transactionCode } = useParams();
  const state = useSelector((rootState: IRootState) => rootState);
  const [loading, setLoading] = React.useState(false);
  const { transactionData } = state.reviewResult;
  const name = state.agent?.info?.name || '';

  useEffect(() => {
    setLoading(true);
    if (transactionCode && !transactionData) {
      GetTransactionDataAPI(transactionCode).then((response) => {
        dispatch(reviewResultActions.setTransactionData({
          transactionData: response.data,
          transactionCode,
        }));
      }).catch(() => {
        UnlockTransactionAPI(transactionCode);
        navigate(ERoutePaths.dashboard);
      });
    } else {
      setLoading(false);
    }
  }, [transactionData]);

  const BigButtonProps: ISingleButtonProps = {
    textButton: 'reg.ta-boarding.button',
    onClick: () => navigate(`${ERoutePaths.identityReview}/${transactionCode}`),
    isActive: true,
    sizeButton: BUTTON_STYLE.BIG,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
  };

  const back = () => {
    if (transactionCode) UnlockTransactionAPI(transactionCode);
    navigate(ERoutePaths.dashboard);
  };

  const docTypes = [['passport' ,'che']];

  if (loading) {
    return <div className="loader-container"><Loader /></div>;
  }

  return (
    <>
      <InfoBar />
      <div className="ta-boarding">
        <div className="ta-boarding-info">
          <p className="ta-boarding-title">
            {t('reg.ta-boarding.hi')}
            {' '}
            {name}
            {' '}
            {t('reg.ta-boarding.please review')}
            {' '}
            { transactionCode }
          </p>
          <>
            <p className="ta-failed-text">{t('reg.ta-boarding.reasons')}</p>
            <ul className="ta-failed-reason">
              {getReturnCodeKeys(transactionData?.returnCodes).map((fail) => (
                <li key={`TAFailedReason_${fail}`}>{t(fail)}</li>))}
            </ul>
          </>
          <p>{t('reg.ta-boarding.transaction-contains')}</p>
          <ul className="ta-swiss">
            {docTypes.map((docType: string[], index: number) => (
              <li key={`${`TADocType${docType[0]}`}-${index.toString()}`}>
                {translateDocTypeCountry(docType, t)}
              </li>
            ))}
          </ul>
          <p>{t('reg.ta-boarding.required-steps')}</p>
          <p className="ta-check-type">{t('reg.ta-boarding.ta-check-type')}</p>
        </div>
        <div className="ta-boarding-buttons-container">
          <div className="ta-boarding-back-button-container">
            <SingleButton
              textButton={'reg.files.back'}
              onClick={back}
              variant={BUTTON_STYLE.OUTLINE_PRIMARY}
              radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
              LeftIcon={BackButton}
            />
          </div>
          <div className="start-transaction-button-ta-boarding">
            <SingleButton {...BigButtonProps} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TABoarding;
