import { ECountry, EDocumentType, EField, EImageSide } from 'enums';
import { IGenericProps } from 'types';

export const PERMIT = 'permit';
export const SOMETHING = 'something';
export const UNKNOWN = 'Unknown';
export const PASS = 'pass';
export const LOCAL_STORAGE_LNG = 'i18nextLng';
export const DEFAULT_EXPIRE_VALUE = '**********';

export const OTHER_IMAGE_SIDES = [
  EImageSide.other,
  EImageSide.signature,
];

export const BUTTON_TYPE = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  OPTION_BUTTON: 'optionButton',
  RADIO_BUTTON: 'radioButton',
  SELECT_OPTION: 'selectOption',
};

export const BUTTON_STYLE = {
  OUTLINE_PRIMARY: 'outline-primary',
  PRIMARY: 'primary',
  OUTLINE_SECONDARY: 'outline-secondary',
  SECONDARY: 'secondary',
  BIG: 'big',
  OUTLINE_BIG: 'big-outline',
  INLINE_BIG: 'big-inline',
  CIRCLE_BUTTON: 'circle-button',
  CIRCLE_GROUP_BUTTONS: 'circle-group-buttons',
  CIRCLE_BUTTON_START: 'circle-group-buttons-start',
  CIRCLE_BUTTON_END: 'circle-group-buttons-end',
};

export const KEY_CODE = {
  TAB: 9,
  ENTER: 13,
  SPACE: 32,
};

export const SELF_DECLARATION_EXTRACTED_DIFFERENCE: IGenericProps = {
  dateOfBirth: 'birthdate',
  documentCountry: 'countryCode',
}

export const MRZ_INFO_MAPPING: IGenericProps = {
  lastName: 'primaryIdentifier',
  firstName: 'secondaryIdentifier',
  expirationDate: 'dateOfExpiry',
  documentCountry: 'issuingState',
}

export const DRIVING_FIELDS = ['value', 'issuingDate', 'restrictions', 'expiryDate'];

export const DEFAULT_DRIVING_FIELDS = {
  value: {
    extractedValue: '',
    reviewedValue: '',
    optional: false,
  },
  issuingDate: {
    extractedValue: '',
    reviewedValue: '',
    optional: false,
  },
  expiryDate: {
    extractedValue: '',
    reviewedValue: '',
    optional: true,
  },
  restrictions:  {
    extractedValue: '',
    reviewedValue: '',
    optional: true,
  },
}

export const DUID_LABEL_MAPPING: IGenericProps = {
  'che-driving_licence-2003': '2003',
  'che-driving_licence-2023': '2023',
  'che-permit-2008-non_eu': 'before2018',
  'che-permit-2010-non_eu': 'before2018',
  'che-permit-2018-non_eu': 'before2018',
  'che-permit-2019-eu': 'after2018',
  'che-permit-2020-non_eu': 'after2018',
  'che-permit-2022-asylum': 'after2018',
  'deu-permit-2011': '2011',
  'deu-permit-2019': '2019',
  'che-id-2003': '2003',
  'che-id-2005': '2003',
  'fra-id-1994': '1994',
  'fra-id-2021': '2021',
}

export const COMPARE_LIST = [
  EField.lastName,
  EField.firstName,
  EField.documentNumber,
];

export const LOADING_DURATION = 5 * 1000; // 5 seconds

export const REQUIRED_FIELDS = [
  EField.lastName,
  EField.firstName,
  EField.dateOfBirth,
];

export const SUPPORTED_EXTRA_DETAILS = [
  `${EDocumentType.plasticPermit}-${ECountry.che}`,
  `${EDocumentType.paperPermit}-${ECountry.che}`,
  `${EDocumentType.id}-${ECountry.can}`,
  `${EDocumentType.id}-${ECountry.usa}`,
  `${EDocumentType.drivingLicence}-${ECountry.can}`,
  `${EDocumentType.drivingLicence}-${ECountry.usa}`,
  `${EDocumentType.healthCard}-${ECountry.can}`,
];